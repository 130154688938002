import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GAContext } from "../../../GAContext";
import AuthService from "../../../services/auth.service";
import EventBus from "../../../services/eventBus";
import { ACTIONS } from "../../../store/reducer";
import { AppContext } from "../../../store/store";
import { useOutsideAlerter } from "../../../utils/useOutsideAlerter";
import Button from "../../Button/Button";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import Login from "../Login/Login";
import Signup from "../Signup/Signup";
import styles from "./Auth.module.scss";

// REDUX ACTIONS
import { channelLists } from "../../../redux/actions/streamer";
import { userProfileData } from "../../../redux/actions/user";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SubscribeModal from "../../Modals/SubscribeModal/SubscribeModal";
export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};

const Auth = ({
  modeDefault = null,
  followButton = false,
  subscribeButton = false,
  disableAuthBtn = false,
  disableJoinBtn = false,
  showSignUpModal,
  ...props
}) => {
  const authModalRef = useRef(null);
  const [mode, setMode] = useState(modeDefault);
  const { dispatch } = React.useContext(AppContext);
  const ReactGAContext = useContext(GAContext);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user && Object.keys(user).length > 0) {
      AuthService.verifyAuthToken()
        .then(async () => {
          props.userProfileData(user);
          // await StreamerService.getChannels().then((data) =>
          //   props.channelLists(data && data.channels)
          // );
          dispatch({ type: ACTIONS.SET_USER, payload: user });
        })
        .catch(() => {
          dispatch({ type: ACTIONS.SET_USER, payload: {} });
        });
    }
  }, []);

  React.useEffect(() => {
    setListeners();
    return () => unSetListeners();
  }, []);

  const setListeners = () => {
    EventBus.on("open-auth-modal", onOpenEvent);
  };

  const unSetListeners = () => {
    EventBus.remove("open-auth-modal", onOpenEvent);
  };

  const onOpenEvent = (mode) => {
    setMode(mode);
  };

  function setLoginMode() {
    setMode("login");
  }

  function setSignUpMode() {
    setMode("sign-up");
  }

  const closeModalButtonClicked = () => {
     setMode(null);
  };

  const closeModal = () => {
    //
  }

  const getAuthComponent = () => {
    ReactGAContext.modalview(`/${mode}`);
    if (mode === null) {
      return <></>;
    } else if (mode === "login") {
      return (
        <div>
          <Login
            setSignUpMode={setSignUpMode}
            handleForgetPassword={() => setMode("forgot-password")}
          />
        </div>
      );
    } else if (mode === "sign-up") {
      return (
        <div>
          <Signup closeModal={closeModal} setLoginMode={setLoginMode} />
        </div>
      );
    } else {
      return (
        <div>
          <ForgotPassword setLoginMode={setLoginMode} />
        </div>
      );
    }
  };

  useOutsideAlerter(authModalRef, () => {
    if (showSignUpModal) showSignUpModal();
    closeModal();
  });
  // if(sidebar){

  // }
  return (
    <>
      {!disableAuthBtn && (
        <div className={styles.authBtnWrapper}>
          {!disableJoinBtn && (
            <Button className="primaryBtn" onClick={setSignUpMode}>
              Join
            </Button>
          )}
          {followButton ? (
            <Button className={styles.followButton} onClick={setLoginMode}>
              Follow
            </Button>
          ) : subscribeButton ? (
            <Button className="subscribeButton" onClick={setLoginMode}>
              Subscribe
            </Button>
          ) : (
            <Button className="primaryBtn" onClick={setLoginMode}>
              Log In
            </Button>
          )}
        </div>
      )}
      {!!mode && (
        <div className={styles.authModalOverlay}>
          <div ref={authModalRef} className={styles.authModal}>
            <button
              className={styles.closeModalButton}
              onClick={closeModalButtonClicked}
            >
              X
            </button>
            <div className={styles.authHeader}>
              <Link onClick={(e) => e.preventDefault()} to="/">
                <span>Politix</span>
              </Link>
            </div>
            {getAuthComponent()}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      userProfileData,
      channelLists,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
