import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import channelInvNotification from "../../../assets/notifications/channelInvNotification.svg";
import friendRequestNotification from "../../../assets/notifications/friendRequestNotification.svg";
import generalNotification from "../../../assets/notifications/generalNotification.svg";
import { channelLists } from "../../../redux/actions/streamer";
import AuthService from "../../../services/auth.service";
import EventBus from "../../../services/eventBus";
import StreamerService from "../../../services/streamer.service";
import { ACTIONS } from "../../../store/reducer";
import { AppContext } from "../../../store/store";
import { useOutsideAlerter } from "../../../utils/useOutsideAlerter";
import AppQuiz from "../../generic/AppQuiz/AppQuiz";
import styles from "./UserMenu.module.scss";

// REDUX ACTIONS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { notificationsList } from "../../../redux/actions/notifications";
ReactGA.initialize("UA-219193792-1");
export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};

const UserMenu = ({
  user,
  channelList,
  notificationList,
  triggerGetNofitication,
  ...props
}) => {
  const history = useNavigate();
  const location = useLocation();
  const userMenuRef = useRef(null);
  const userNotificationRef = useRef(null);
  const [showQuiz, setShowQuiz] = useState(false);
  const [notification, setNotifications] = useState(notificationList);
  const { dispatch } = useContext(AppContext);
  const [isNavDropdownOpened, setIsNavDropdownOpened] = useState(false);
  const [isNotificationsDropdownOpened, setIsNotificationsDropdownOpened] =
    useState(false);
  const [userPerms, setUserPerms] = useState(false);
  const [isVisible, setIsVisible] = useState([]);
  const [channelID, setChannelID] = useState(user.channel_id);

  const visibleList = {};
  useEffect(() => {
    if (notification?.length) {
      for (const notif of notification) {
        const id = String(notif.id);
        if (notif["is_seen"]) {
          visibleList[id] = false;
        } else {
          visibleList[id] = true;
        }
        setIsVisible(visibleList);
      }
    }
  }, [notification]);


    useEffect(() => {
      AuthService.getNotifications().then((data) =>
        {setNotifications(data)
        notificationsList(data)}
      );
    }, [user?.access]);

  if (!showQuiz && location.search.indexOf("quiz") >= 0) {
    setShowQuiz(true);
  }

  useEffect(() => {
    const notifs = [];
    if(notificationList?.length){
      for (let i = 0; i < notificationList.length; i++) {
        if (!notificationList[i].is_seen) {
          notifs.push(notificationList[i]);
        }
      }
      setNotifications(notifs);
    }
  }, [notificationList]);

  useEffect(() => {
    setListeners();
    return () => unSetListeners();
  }, []);

  useEffect(() => {
    getUserPerms(user.access);
  }, []);

  const setListeners = () => {
    EventBus.on("update-user-profile", onUpdateProfile);
  };

  const unSetListeners = () => {
    EventBus.remove("update-user-profile", onUpdateProfile);
  };

  const onUpdateProfile = () => {
    AuthService.fetchCurrentUserProfile().then((profile) => {
      dispatch({ type: ACTIONS.SET_USER, payload: profile });
    });
  };

  const onLogout = () => {
    dispatch({ type: ACTIONS.SET_USER, payload: {} });
    ReactGA.event({
      category: "User",
      action: "Logged Out",
    });
    AuthService.logout();
    if (
      location["pathname"].includes("profile") ||
      location["pathname"].includes("streamer")
    ) {
      history("/explore/");
    }
  };

  const closeQuiz = () => {
    const urlParams = new URLSearchParams(location.search);
    // Remove the quiz param to stop showing the quiz pop-up and update in history
    urlParams.delete("quiz");
    history({
      pathname: location.pathname,
      search: urlParams.toString(),
    });
    setShowQuiz(false);
  };

  const getUserPerms = (access) => {
    fetch(`https://politix.tv/api/users/streamer/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUserPerms(data);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const toggleNavDropdown = () => {
    setIsNavDropdownOpened((prevOpened) => !prevOpened);
    if (isNotificationsDropdownOpened) {
      setIsNotificationsDropdownOpened(false);
    }
  };

  const toggleNotificationDropdown = () => {
    setIsNotificationsDropdownOpened((prevOpened) => !prevOpened);
    if (isNavDropdownOpened) {
      setIsNavDropdownOpened(false);
    }
  };

  useOutsideAlerter(userMenuRef, () => setIsNavDropdownOpened(false));

  useOutsideAlerter(userNotificationRef, () =>
    setIsNotificationsDropdownOpened(false)
  );

  const isMobile = useMediaQuery({ maxWidth: 984 });

  //not being used anymore
  // const ackInvite = async (invite_status, inviteId) => {
  //   await StreamerService.ackInvite({
  //     inviteId,
  //     invite_status,
  //   })
  //     .then(async (data) => {
  //       triggerGetNofitication();
  //       await StreamerService.getChannels().then((data) =>
  //         props.channelLists(data && data.channels)
  //       );
  //       Swal.fire({
  //         icon: "success",
  //         title: data.message,
  //         showConfirmButton: false,
  //         timer: 1500,
  //       });
  //     })
  //     .catch((err) => {
  //       if (err && err.message)
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: err.message,
  //         });
  //       else
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "Internal Server Error !",
  //         });
  //     });
  // };

  const handleRemoveNotification = (notif) => {
    // Filter out the notification with the same ID as the one being removed
    // const updatedNotifications = [];
    const userNotif = JSON.parse(localStorage.getItem("user"));
    for (let i = 0; i < notification.length; i++) {
      if (notification[i].id === Number(notif.target.id)) {
        //patch request to remove notification
        setIsVisible((prevState) => ({
          ...prevState,
          [notification[i].id]: false,
        }));
        const notifs = [];
        fetch("https://politix.tv/api/livestream/notification/", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userNotif.access}`, // ${TOKEN_VALUE}
          },
          body: JSON.stringify({
            notification_id: `${notification[i].id}`,
          }),
        })
          .then((response) => {
            for (let i = 0; i < notificationList.length; i++) {
              if (!notificationList[i].is_seen) {
                notifs.push(notificationList[i]);
              }
            }
            setNotifications(notifs);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const acceptRequest = (notif) => {
    fetch(`https://politix.tv/api/livestream/friends/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.access}`, // ${TOKEN_VALUE}
        body: JSON.stringify({
          request_id: notif["friend_request"]["id"],
        }),
        redirect: "follow",
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const rejectRequest = (notif) => {
    fetch(`https://politix.tv/api/livestream/friends/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.access}`, // ${TOKEN_VALUE}
        body: JSON.stringify({
          request_id: `${notif["friend_request"]["id"]}`,
        }),
        redirect: "follow",
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleInvite = (val, notif) => {
    // Filter out the notification with the same ID as the one being removed
    // const updatedNotifications = [];
    const userNotif = JSON.parse(localStorage.getItem("user"));
    for (let i = 0; i < notification.length; i++) {
      if (notification[i].id === Number(notif.id)) {
        //patch request to remove notification
        let id = notif.id;
        setIsVisible((prevState) => ({
          ...prevState,
          id: false,
        }));
        if (val === 1) {
          acceptRequest(notification[i]);
        } else if (val === 2) {
          rejectRequest(notification[i]);
        }
        // fetch(
        //   `https://politix.tv/api/livestream/notification/?id=${notification[i].id}&val=${val}`,
        //   {
        //     method: "PATCH",
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${userNotif.access}`, // ${TOKEN_VALUE}
        //     },
        //   }
        // )
        //   .then((response) => {
        //     console.log(response);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      }
    }
  };
  const notificationDiv = (notif) => {
    let notifSubType = notif['notification_type'];
    let iconImg = generalNotification;
    switch (notifSubType) {
      case "channel_invite": {
        iconImg = channelInvNotification;
        break;
      }
      case "friend request": {
        iconImg = friendRequestNotification;
        break;
      }
      default: {
        iconImg = generalNotification;
      }
    }

    return (
      <>
        <div className={styles.notifWrapper}>
          {isVisible[notif.id] && (
            <div className={styles.notifFlex}>
              <img src={iconImg} />
              <a onClick={toggleNavDropdown}>
                <span>{notif.message}</span>
              </a>
              <button
                onClick={handleRemoveNotification} //commenting this out until after talking to avi and debugging the issue
                style={{
                  width: "20px",
                  height: "20px",
                  fontSize: "16px",
                  boxShadow: "none",
                  background: "none",
                  color: "grey",
                  fontStyle: "bold",
                  fontFamily: "Nunito",
                  margin: "0 0 0 0",
                }}
                id={notif.id}
              >
                X
              </button>
            </div>
          )}
          {(notifSubType === "channel_invite" ||
            notifSubType === "friend request") &&
            notif.isAccept !== "1" &&
            notif.isAccept !== "2" && !notif.is_seen && (
              <div className={styles.inviteBtn}>
                <input
                  type="button"
                  className={styles.accept}
                  onClick={(e) => handleInvite(1, notif)}
                  value="Accept"
                />
                <input
                  type="button"
                  className={styles.reject}
                  onClick={(e) => handleInvite(2, notif)}
                  value="Decline"
                />
              </div>
            )}
        </div>
      </>
    );
  };

  return (
    <div className={`${styles.userMenu} ${isMobile && styles.userMenuMobile}`}>
      {showQuiz && <AppQuiz closeQuiz={closeQuiz} />}
      <div className={styles.avatarWrapper}>
        {user["avatar"] ? (
          <div className={styles.flexRow}>
            <NotificationsNoneIcon
              id="notification-icon"
              onClick={toggleNotificationDropdown}
              className="mr-2"
              sx={{ fontSize: 40, color: "white", height: "36px" }}
            />
            <a href="/profile/me/settings">
              <img id="notification-icon" src={user["avatar"]} alt="avatar" />
            </a>
          </div>
        ) : (
          <div className="d-flex">
            <NotificationsNoneIcon
              id="notification-icon"
              onClick={toggleNotificationDropdown}
              className="mr-2"
              sx={{ fontSize: 40, color: "white", height: "36px" }}
            />
            <a href="/profile/me/settings">
              <i id="notification-icon" className="far fa-user-circle" />
            </a>
          </div>
        )}
        {/* {isNavDropdownOpened && (
          <div
            style={{
              top: user["avatar"] ? "46px" : "42px",
            }}
            ref={userMenuRef}
            className={styles.userMenuDropdown}
          >
            <Link
              className={styles.userMenuAvatarWrapper}
              to="profile/me/settings"
              onClick={toggleNavDropdown}
            >
              {user["avatar"] ? (
                <img src={user["avatar"]} className={styles.profileImg} />
              ) : (
                <i className="far fa-user-circle" />
              )}
              <p>
                {(user["username"] || "").split(" ").join("").length >= 9
                  ? `${(user["username"] || "").substring(0, 9)}...`
                  : user["username"]}
              </p>
            </Link>
            <div className={styles.dropdownMenu}>
              <Link
                to={{
                  pathname: "profile/me/settings",
                }}
                onClick={toggleNavDropdown}
              >
                <span>Profile</span>
              </Link>
              <Link
                onClick={toggleNavDropdown}
                to={{
                  pathname: "friends",
                }}
              >
                <span>
                  Friends{" "}
                  <span className={styles.friendsNum}>
                    {!!user["friend_requests"] && user["friend_requests"]}
                  </span>
                </span>
              </Link>
              {userPerms && (
                <Link
                  onClick={toggleNavDropdown}
                  to={
                    !!channelID
                      ? `/streamer/${channelID}`
                      : "/live/channel-onboard?step=welcome"
                  }
                  // to="/live/channel-onboard?step=welcome"
                >
                  <span>Channel</span>
                </Link>
              )}
              <Link onClick={toggleNavDropdown} to="/profile/me/settings">
                <span>Settings</span>
              </Link>
              <button
                className="logout-btn"
                onClick={() => {
                  onLogout();
                  toggleNavDropdown();
                }}
              >
                Logout
              </button>
            </div>
          </div>
        )} */}
        {isNotificationsDropdownOpened && (
          <div
            style={{
              top: user["avatar"] ? "46px" : "42px",
            }}
            ref={userNotificationRef}
            className={styles.useNotificationDropDown}
          >
            <div className={styles.notificationHeading}>Notifications</div>
            <div className={styles.dropdownMenu}>
              {notification &&
                notification.map((notif) => notificationDiv(notif))}
              {notification && notification.length === 0 && (
                <div className={styles.emptyNotification}>
                  <FolderOpenIcon fontSize="large" />
                  No New Notifications
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    notificationList: state.notifications.notificationList,
    channelList: state.streamer.channelLists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      channelLists,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserMenu)
);
