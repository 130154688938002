import React from "react";
import styles from "./SidebarRight.module.scss"; // Import the SCSS module
// import HomeIcon from '@mui/icons-material'

// import CentralItem1 from "./CentralItem1";
// import CentralItem2 from "./CentralItem2";
// import CentralItem3 from "./CentralItem3";
import { Link, useNavigate } from "react-router-dom";
// import FeaturedSection from "./FeaturedSectionV3";
// import Discover from "../Discover/Discover"
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { AppContext } from "../../store/store";

const Sidebar = () => {
  const [user, setUser] = React.useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [trendingStreamers, setTrendingStreamers] = React.useState([]);
  const [trendingTopics, setTrendingTopics] = React.useState([]);

  const { userProfile } = React.useContext(AppContext);

  React.useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  const history = useNavigate();
  const handleSidebarItemClick = (route) => {
    history(route);
  };
  //   curl --location --request GET 'https://politix.tv/api/livestream/content/TAGS}}' \
  // --header 'Content-Type: application/json' \
  const getTrendingTopics = () => {
    fetch(`https://politix.tv/api/livestream/content/tags`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTrendingTopics(data.slice(0, 6));
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };
  //   curl --location --request GET 'https://politix.tv/api/livestream/content/FOLLOWED}}' \
  // --header 'Content-Type: application/json' \
  const getTrendingStreamers = () => {
    fetch(`https://politix.tv/api/livestream/content/followed`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTrendingStreamers(data);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  React.useEffect(() => {
    getTrendingStreamers();
    getTrendingTopics();
  }, []);

  return (
    <>
      <div className={styles.containerPadding}>
        <div className={styles.sidebar}>
          <div className={styles.sidebarTitle} style={{ paddingTop: "18px" }}>
            <ShowChartIcon
              style={{
                color: "$newDarkBlue",
                marginRight: "5px",
                paddingTop: "5px",
              }}
            />
            Top Streamers
          </div>
          <ol className={styles.trendingStreamersList}>
            {trendingStreamers.map((streamer, index) => (
              <li
                className={styles.trendingStreamer}
                onClick={() =>
                  handleSidebarItemClick(`/channel/${streamer.id}`)
                }
              >
                <span className={styles.listNumber}>{index + 1}</span>
                {streamer.name}
              </li>
            ))}
          </ol>
          <div
            className={styles.sidebarTitle}
            style={{ paddingTop: "10px", paddingBottom: "15px" }}
          >
            <ShowChartIcon
              style={{
                color: "$newDarkBlue",
                marginRight: "5px",
                paddingTop: "5px",
              }}
            />
            Trending Topics
          </div>
          <ul className={styles.trendingTopicsList}>
            {trendingTopics.map((topic) => (
              <Link
                to={`/search?q=${topic.tag_name}`}
                key={topic.tag_name}
                className={styles.removeLinkStyling}
              >
                <li className={styles.trendingTopic}>{topic.tag_name}</li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
