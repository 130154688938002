import App from "./App.js";
import React from "react";
import ReactGA from "react-ga";
const user = JSON.parse(localStorage.getItem("user"));
// console.log(
//   "User Session - ",
//   user ? user["id"] : `visiter${new Date().toISOString()}`
// );
ReactGA.initialize("UA-219193792-1", {
  titleCase: false,
  gaOptions: {
    name: "UserTracker",
    cookieDomain: "https://politix.tv",
    userId: user ? user["id"] : `visiter${new Date().toISOString()}`,
  },
});

const GAContext = React.createContext(ReactGA);

function ReactGAContext() {
  return (
    <GAContext.Provider value={ReactGA}>
      <App />
    </GAContext.Provider>
  );
}

export { GAContext };
export default ReactGAContext;
