import React from "react";
import styles from "./Donation.module.scss";

const Donation = () => {
  return (
    <form action="https://www.paypal.com/donate" method="post" target="_top">
      <input type="hidden" name="hosted_button_id" value="WERZFM95M5PWJ" />
      <input
        type="submit"
        name="Donate"
        value="Support"
        className={styles.link}
      />
      {/* <input
        type="image"
        style={{ width: "40%" }}
        src="https://pics.paypal.com/00/s/YjBlYTlmNjctMzNmOC00NmZmLTk5ZGMtZWQxMzNhYTI5YTc4/file.PNG"
        border="0"
        name="submit"
        title="PayPal - The safer, easier way to pay online!"
        alt="Donate with PayPal button"
      /> */}
      <img
        alt=""
        border="0"
        src="https://www.paypal.com/en_US/i/scr/pixel.gif"
        width="1"
        height="1"
      />
    </form>
  );
};

export default Donation;
