import React, { useRef, useEffect } from "react";
import VideoService from "../../services/videos";
import SearchResult from "./SearchResult/SearchResult";
import { DebounceInput } from "react-debounce-input";
import { useNavigate } from "react-router-dom";
import "../../assets/css/components/search-drowpdown.scss";

const SearchDropdown = () => {
  const ref = useRef(null);
  const [searchText, setSearchText] = React.useState("");
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [searchResults, setResults] = React.useState({
    channels: [],
    videos: [],
    people: []
  });
  const history = useNavigate();

  useEffect(() => {
    const getResult = async () => {
      await VideoService.searchContent(searchText, true).then((response) => {
        setResults({channels : response['Channel Results'], videos : response['Recordings']});
        setShowDropdown(true);
      });
    };
    if (searchText.length) getResult();
    else setShowDropdown(false);
  }, [searchText]);

  const onTextChange = async (query) => {
    setSearchText(query);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowDropdown(false);
    history("/search?q=" + searchText);
  };

  const closeSearchDropdown = () => {
    setSearchText("");
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setSearchText("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="searchContainer" ref={ref}>
      <div className="searchWrapper">
        {!!searchText.length && (
          <SearchResult
            closeSearchDropdown={closeSearchDropdown}
            showDropdown={showDropdown}
            {...searchResults}
          />
        )}
        <label htmlFor="search">
          <i className="fas fa-search" />
        </label>
        <form onSubmit={(e) => handleSubmit(e)}>
          <DebounceInput
            id="search"
            type="text"
            placeholder="Search"
            value={searchText}
            minLength={2}
            debounceTimeout={300}
            onChange={(e) => onTextChange(e.target.value)}
          />
        </form>
      </div>
    </div>
  );
};

export default SearchDropdown;
