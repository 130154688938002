import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Container from "../Container/Container";
import Donation from "../donation/Donation";
import styles from "./Footer.module.scss";

function Footer() {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 984 });
  const showYear = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
  });
  return (
    <footer className={styles.footer}>
      <Container className={styles.footerContainer}>
        <div className={styles.footerRow}>
          <Link className={styles.logo} to="/">
            <span>Politix</span>
          </Link>
          <p>
            &copy; {showYear.format(new Date())} Politix. All rights reserved.
          </p>
        </div>
        <div className={styles.footerRow}>
          <h4>General</h4>
          {/* <Link to="/our-story">Our Story</Link> */}
          <Link to="/faq">FAQ</Link>
          <Link to="/terms-of-use">Terms of Use</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/contact">Contact</Link>
        </div>
        <div className={styles.footerRow}>
          <h4>Follow Us</h4>
          <a href="https://www.facebook.com/Politix.tv" target="_blank">
            <i className="fab fa-facebook-f"></i>
            facebook.com/Politix.tv
          </a>
          <a href="https://twitter.com/PolitixTV" target="_blank">
            <i className="fab fa-twitter"></i>
            twitter.com/PolitixTV
          </a>
          <a href="https://www.instagram.com/politix.tv/" target="_blank">
            <i className="fab fa-instagram"></i>
            instagram.com/politix.tv
          </a>
        </div>
        <div className={styles.footerRow}>
          <h4>Resources</h4>
          <Link
            to={{
              pathname: "/faq",
              state: location,
            }}
          >
            What is Politix?
          </Link>
          {isMobile ? (
            <div className={styles.button}>
              <Donation />
            </div>
          ) : null}
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
