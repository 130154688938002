import React from "react";
import { Link } from "react-router-dom";
import VideoService from "../../../services/videos";
import AuthService from "../../../services/auth.service";
import styles from "./SearchResult.module.scss";

const SearchResult = ({
  channels,
  videos,
  people,
  closeSearchDropdown,
  showDropdown,
}) => {

  const getDefaultChannelThumbnail = (channelTitle) => {
    if (channelTitle) {
      return (
        <div className="channelThumbnailWrapper">
          <p className="channelDefaultTitle">
            {channelTitle.charAt(0).toUpperCase()}
          </p>
        </div>
      );
    }
  };

  return (
    <div
      style={{
        display: showDropdown ? "block" : "none",
        padding:
          channels?.length === 0 && videos.length === 0 ? "0" : "2px 0 14px 0",
      }}
      className={styles.searchResults}
    >
      {channels && channels.length > 0 && (
        <div className={styles.searchResultsWrapper}>
          <h4>Channels</h4>
          <div className={styles.searchResultsInfo}>
            {channels.map((channel) => (
                <Link
                  to={VideoService.getChannelURL(channel)}
                  onClick={closeSearchDropdown}
                  key={channel["id"]}
                >
                  {channel["image"] ? (
                    <img src={channel["image"]} alt="channel-thumbnail" />
                  ) : (
                    getDefaultChannelThumbnail(channel["title"])
                  )}
                  <p>{channel["title"]}</p>
                </Link>
              )
              )}
          </div>
        </div>
      )}
      {videos && videos.length > 0 && (
        <div className={styles.searchResultsWrapper}>
          <h4>Videos</h4>
          <div className={styles.searchResultsInfo}>
            {videos.map((video) => (
              <Link
                to={VideoService.getVideoURL(video)}
                key={video["id"]}
                onClick={closeSearchDropdown}
              >
                <img src={video["thumbnail"]} alt="thumbnail" />
                <p>{video["title"]}</p>
              </Link>
            ))}
          </div>
        </div>
      )}
      {people && people.length > 0 && (
        <div className={styles.searchResultsWrapper}>
          <h4>People</h4>
          <div className={styles.searchResultsInfo}>
            {people.map((user) => (
                <Link
                  className="my-3"
                  to={AuthService.getUserProfileURL(user)}
                  key={user["id"]}
                  onClick={closeSearchDropdown}
                >
                  {user["avatar"] ? (
                    <img src={`http://politix.tv${user["avatar"]}`} alt="avatar" />
                  ) : (
                    <i className="far fa-user-circle" />
                  )}
                  {/* <img src={user["avatar"]} alt="avatar" /> */}
                  <p>{user["username"]}</p>
                </Link>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResult;
